<template>
  <v-container>
    <breadcrumbs class="mb-4" :items="breadcrumbs"></breadcrumbs>
    <placeholder
      header="brand-ev"
      text="association-placeholder"
      :buttons="buttons"
    ></placeholder>
  </v-container>
</template>
<script>
import Placeholder from "@/components/Placeholder.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "overview",
  components: {
    Placeholder,
    Breadcrumbs
  },
  data: () => ({
    buttons: [
      {
        slug: "association-placeholder-button",
        icon: "mdi-bee-flower",
        url: "/"
      },
      {
        slug: "mail",
        icon: "mdi-email-edit-outline",
        url: "/contact"
      }
    ]
  }),
  computed: {
    breadcrumbs: function() {
      return [
        {
          text: this.$t("home"),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("association"),
          disabled: true
        }
      ];
    }
  }
};
</script>
